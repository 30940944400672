
(function($){
	'use strict';
	$(document).ready(function(){
		contactFormValidation();
	});
function contactFormValidation() {
    $( "#contact" ).validate({
        // rules
        rules: {
            name: {
                required: true,
                minlength: 3
            },
            phone: {
                required: true,
                number: true,
                minlength: 9
            },
            email: {
                required: true,
                email: true
            },
          
            subject: {
                required: true,
                minlength: 4
            },
            message: {
                required: true,
                minlength: 4,
                maxlength: 800
            }
        },
        
    });

    $( "#contact" ).on( "submit", function(event) {
                  $("#submit").html( 'A Enviar...');
                //   gtag_report_conversion();
      $("#submit").attr("disabled", true);
 
        if ( event.isDefaultPrevented() ) {

     
            formError();
            var errorContent =  '<img src="imgs/error.png">' +
                                '<h5 style="color:#C75C5C;">Upsss!</h5>' +
                                '<p>Verifique os Campos introduzidos.</p>';
            submitMSG(false, errorContent);
        } else {
             
            event.preventDefault();
            submitForm();
        }
    });  
}

$.ajaxSetup({
		headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
	});

function submitForm(){


    $.ajax({
        
        type: "POST",
        url: "contact/send/mail",
        data:  $('#contact').serialize(),
        
        success : function(data){
   
            if (Object.keys(data)[0] == 'success'){
          
                formSuccess();
            } else {
                     
                formError();
                submitMSG(false,Object.values(data)[0]);
            }
        }
    });
}



function formSuccess(){
    $("#contact")[0].reset();

    var successContent =  '<img src="imgs/send.png">' +
                          '<h5 style="color:#4F5D73;">Mensagem Enviada</h5>' +
                          '<p>O seu formulário foi enviado para a From Scratch obrigado!</p>';
  submitMSG(true, successContent);

}



function formError(){
    //Animation Phone Form when made error in validation
    $("#contact").addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
        $(this).removeClass('shake animated');

    });
}



function submitMSG(valid, msg){
    var msgClasses;
    if(valid){
        //Animation Form Notification when it appear
        msgClasses = "bounceInRight animated";
    } else {
        msgClasses = "bounceInRight animated appearing-delay";
    }
   
    $("#msgSubmit").removeClass().addClass(msgClasses).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
        $(this).removeClass(msgClasses);
    });
    
    $("#msgSubmit").children(".msgSubmit-content").html(msg);
    window.setTimeout(function(){$("#msgSubmit").addClass("hidden-form");}, 8000);
  
    $("#submit").attr("disabled", false);
    $("#submit").html( 'Enviar');
}



//Animation Form Notification when click to close icon
$('#msgSubmit').find('i').on( "click", function() {
    $(this).parent("#msgSubmit").addClass( "bounceOutLeft animated hidden-form" ).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
        $(this).removeClass( "appearing-delay" );
    });
});

})(jQuery);
